.container-404 button {
    font-family: 'Open Sans', 
  }
  .container-404 svg {
    width: 100%;
  }
  .container-404 h1 {
    font-size: 8em;
    font-weight:700;
    margin: 15px 0px;
    line-height: 1.125em;
    letter-spacing: .05em;
    text-shadow:(0px 0px 18px rgba(203,255,232,.6));
  }
  .container-404 h2 {
    font-size: 2em;
    font-weight:400;
    line-height: 1.25em;
  }
  .container-404 p {
    font-size: 1.1em;
    line-height: 1.5em;
    max-width: 30em;
    padding-bottom: .8em;
  }
  .container-404 {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1em; 
    justify-content: space-between; 
    align-items: center; 
    background-color: black;
    height: 100vh;
    width: 100vw;
    @media screen and (min-width:768px) { 
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .container-404 .text {
    padding: 2rem ;
  }
  
  .container-404 .btn {
    color: var(--white);
    background:  var(--blush);
    border-color:  var(--blush);
    border-radius: 20px;
    padding: 8px 50px;
    cursor: pointer;
    font-size: 1em;
    letter-spacing: .1rem;
    font-weight: 400;
    margin: 5px 0px;
    background-color: #79f3fc;
      
    &:hover {
      color: black;
      background: var(--ghost);
      border-color:  var(--ghost);
      filter: drop-shadow(0px 0px 10px rgba(105, 253, 216, .5));
      transition: .5s;
    }
  }
  
  .container-404 .hide {
     opacity: 0 
  }
  
  .container-404 .body--outer {
    opacity: 1
  }
  
  .container-404 .body--hightlight {
    opacity: .9
  }
  .container-404  .body--hightlight-bright {
    opacity: .5
  }
  
  .container-404 .arm--right-upper,
  .arm--right-upperthumb {
    opacity: 0
  }
  
  .container-404 .ghost-friendly-shadow,
  .ghost-shadow {
    opacity: .4
  }